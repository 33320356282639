// branding configuration
$publication: 'buzz';
$logo-dimensions: 459, 160;
$primary-color: #110505;
$header-additional: #110505;

$brand-hue: 0;
$brand-saturation: 0%;

$brand-color: hsl($brand-hue, $brand-saturation, 0%);
$highlight: hsl($brand-hue, $brand-saturation, 63%);
$hint: hsl($brand-hue, $brand-saturation, 95%);
$lowlight: hsl($brand-hue, $brand-saturation, 0%);

$logo-name: 'logo-buzz';

// overrides
@mixin overrides() {
  .social-sites {
    @include social-icons(true);

    .icon {
      @include icons-background($white, $primary-color);
    }

  }
}
